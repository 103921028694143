import React, {useEffect, useState} from "react";
import iconSuccessLoad from "../../img/AddNewOrgSuccessIcon.svg";
import iconExcelActive from "../../img/AddNewOrgExcelActiveIcon.svg";
import iconExcel from "../../img/AddNewOrgIconExcel.svg";
import {Validation} from "../../utils/Validation/Validation";
import * as Users from "../../Api/Users";
import * as excel from "xlsx";

const AddUsersExcel = (props) => {
    const {
        constants,
        requestHelper,
        changeUsersToAddArr,
        usersToAdd,
        activeExcelBtn,
        setUsersAddExcel,
        setActiveExcelBth,
        selectedFileName,
        setSelectedFileName,
        loadIdsClear,
        isExcelFileSelected,
        setExcelFileSelected
    } = props;

    const usersTextarea = Validation();
    const [usersToFind, setUsersToFind] = useState([]);

    const [message, setMessage] = useState("");
    const regex =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    const onExcelBtnClick = () => {
        loadIdsClear();
        setActiveExcelBth(true);
    };

    useEffect(() => {
        let isMounted = true;

        if (usersToFind.length !== 0) {
            requestHelper(Users.findUsers, usersToFind)
                .then((data) => {
                    const users = [];

                    Object.values(data).forEach((user) => {
                        if (!user.lastName) {
                            const newUser = {
                                id: user.id,
                                email: user.email,
                                first_name: `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_FIRST_NAME}`,
                                last_name: `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_LAST_NAME}`,
                                second_name: `${constants.ADD_NEW_ORG.ADD_NEW_ORG_DEFAULT_SECOND_NAME}`,
                                isAdmin: false,
                                //userFields: user.userFields,
                            };
                            users.push(newUser);
                        } else {
                            const newUser = {
                                id: user.id,
                                email: user.email,
                                first_name: user.firstName,
                                last_name: user.lastName,
                                second_name: user.secondName,
                                isAdmin: false,
                                // userFields: user.userFields,
                            };
                            users.push(newUser);
                        }
                    });
                    isMounted && changeUsersToAddArr(users);
                })
                .catch((err) => {
                    throw new Error(err.message);
                });
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line
    }, [usersToFind]);

    function onSelectFileHandler(e) {
        const validFormatDoc = ['.xlsx', '.xls'];
        const files = e.target.files,
            f = files[0];
        if (validFormatDoc.indexOf(f.name.substring(f.name.lastIndexOf('.'))) === -1) {
            setMessage(`Извините, допускаются только файлы с расширениями ${validFormatDoc.toString()}`)
            setSelectedFileName(constants.ADD_NEW_ORG.ADD_NEW_ORG_SELECT_FILE);
            setExcelFileSelected(false);
            setUsersAddExcel([]);
            return false;
        }

        setSelectedFileName(files[0].name);
        setMessage('');
        setExcelFileSelected(true);
        const reader = new FileReader();
        reader.onload = function (e) {
            const data = e.target.result;
            const workbook = excel.read(data, {type: "binary"});
            const wsname = workbook.SheetNames[0];
            const ws = workbook.Sheets[wsname];
            const dataWs = excel.utils.sheet_to_json(ws, {header: 1});
            const newARR = dataWs.reduce((acc, newEl) => {
                if (!newEl[0] || !newEl[1]) return acc;
                return [
                    ...acc,
                    {
                        email: newEl[0],
                        name: newEl[1],
                    },
                ];
            }, []);

            const splitArr = newARR.map((el) => {
                const fioItem = el.name.split(" ");
                return {
                    email: el.email,
                    first_name: fioItem[1],
                    last_name: fioItem[0],
                    second_name: fioItem[2],
                };
            });

            if (splitArr.length === 0) {
                setSelectedFileName(constants.ADD_NEW_ORG.ADD_NEW_ORG_SELECT_FILE);
                setExcelFileSelected(false);
                setMessage('Ошибка формата таблицы документа(должно быть две колонки: колонка с email, колонка с ФИО)');
                setUsersAddExcel([]);
                return false;
            }

            window.LoadLength = splitArr.length;
            setUsersAddExcel(splitArr);
        };
        // reader.readAsBinaryString(f);
        reader.readAsArrayBuffer(f);
    }

    function findUsers() {
        const usersArray = usersTextarea.value.trim().split(/(?:\n| |,)+/);
        const validUsersEmails = [];
        usersArray.forEach((user) => {
            if (regex.test(String(user).toLowerCase())) {
                validUsersEmails.push(user);
            }
        });
        const uniqАrr = [...new Set(validUsersEmails)];
        if (uniqАrr.length !== 0) {
            setUsersToFind(uniqАrr);
            setMessage("");
        } else {
            setMessage(constants.ADD_NEW_ORG.ADD_NEW_ORG_EMAIL_ERROR);
        }
    }

    return (
        <>
            <div className="add-new-organization__import-excel-add-email-buttons">
                <div
                    onClick={onExcelBtnClick}
                    className={
                        activeExcelBtn
                            ? "import-excel-add-email-buttons__import-excel-btn active"
                            : "import-excel-add-email-buttons__import-excel-btn"
                    }
                >
                    <img
                        alt={constants.GENERAL.ALT_ICON}
                        src={activeExcelBtn ? iconExcelActive : iconExcel}
                        className="import-excel-btn__excel-icon"
                    />
                    <p>{constants.ADD_NEW_ORG.ADD_NEW_ORG_IMPORT_EXCEL_BTN}</p>
                </div>
            </div>
            {activeExcelBtn && (
                <div className="add-new-organization__download-file-load-button">
                    <label className="download-file-load-button__label">
                        {constants.ADD_NEW_ORG.ADD_NEW_ORG_DOWNLOAD_FILE_LABEL}
                        <span className="name-org-input__red-star"> *</span>
                    </label>
                    <div className="add-new-organization__excel-add-container">
                        <input
                            className="add-new-organization__excel-add-input"
                            id="excel__file"
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => onSelectFileHandler(e)}
                        />
                        <label
                            htmlFor="excel__file"
                            className="add-new-organization__excel-add-input-container"
                        >
                            <div className="add-new-organization__excel-add-input-file-name-container">
                                <p
                                    className={`add-new-organization__excel-add-input-file-name-text ${
                                        isExcelFileSelected &&
                                        "add-new-organization__excel-add-input-file-name-text_selected"
                                    }`}
                                >
                                    {selectedFileName}
                                </p>
                            </div>
                            <div className="add-new-organization__excel-add-input-button">
                                <p className="add-new-organization__excel-add-input-button-text">
                                    {constants.ADD_NEW_ORG.ADD_NEW_ORG_LOAD_BTN}
                                </p>
                            </div>
                        </label>
                        {isExcelFileSelected && (
                            <div className="field-file-load-button__success-info">
                                <img
                                    className="field-file-load-button__icon-success"
                                    src={iconSuccessLoad}
                                    alt={iconSuccessLoad}
                                />
                                <p className="field-file-load-button__label">
                                    {constants.ADD_NEW_ORG.ADD_NEW_ORG_SUCCESS_INFO}
                                </p>
                            </div>
                        )}
                        {message && (
                            <div className="field-file-load-button__success-info">
                                <p className="field-file-load-button__label" style={{color: '#FF4970'}}>
                                    {message}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
export default AddUsersExcel;

import React, {useState, useEffect} from "react";
import optionRow from "../../img/Auth_icon_row_select_role.svg";
import searchIcon from "../../img/VotesPageBlock_icon_search.svg";
import {Validation} from "../../utils/Validation/Validation";
import {useMutation} from "@tanstack/react-query";
import {updateUserFromSuperAdmin} from "../../Api/auth-service";
import {useOnClickOutsideModal} from "../../utils/CustomHooks/useOutsideModal/useOutsideModal";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import * as Users from "../../Api/Users";

const ProfileUserUpdateUserData = (props) => {

    const {
        constants,
        allUsers = [],
        isUserProfile = {},
        requestHelper
    } = props;

    const newPass = Validation();
    const {
        setUserProfile,
    } = useCurrentUserContext();
    const [isOptionsOpen, setOptionsOpen] = useState(false);
    const [isDisableBtn, setDisableBtn] = useState(true);
    const [resultForRender, setResultsForRender] = useState([]);
    useOnClickOutsideModal(isOptionsOpen, () => setOptionsOpen(false));

    const [message, setMessage] = useState({
        isError: false,
        text: ''
    });
    const [selectUser, setSelectUser] = useState(
        {
            nameUser: '',
            dataUser: {}
        }
    );

    const updateUserMutation = useMutation({
        mutationFn: ({body}) => {
            return updateUserFromSuperAdmin(body)
        },
        onSuccess: () => {
            setDisableBtn(true);
            // setSelectUser({
            //     nameUser: '',
            //     dataUser: {}
            // });
            requestHelper(Users.getUser, selectUser.dataUser.id)
                .then((data) => {
                    setUserProfile(data);
                })
                .catch((err) => {
                    throw new Error(err.message);
                });
            newPass.setValue('');
            setMessage({
                isError: false,
                text: 'Пароль сохранен!'
            });
            setTimeout(() => {
                setMessage({
                    isError: false,
                    text: ''
                });
            }, 2000)
        },
        onError: (error) => {
            if (error.response.status === 400) {
                if (error.response.data.text.includes('is not valid')) {
                    setMessage({
                        isError: true,
                        text: 'Ваш пароль должен содержать символы нижнего и верхнего регистра, содержать хотя бы одну цифру и символ и иметь длину не менее 8 символов.'
                    })
                } else {
                    setMessage(
                        {
                            isError: true,
                            text: `${error.response.data.text}`
                        })
                }
            } else {
                setMessage(
                    {
                        isError: true,
                        text: `${error.response.data.text}`
                    })
            }
        }
    });

    // useEffect(() => {
    //     let isMounted = true;
    //
    //     isMounted && setResultsForRender(allUsers && allUsers.length !== 0 ? allUsers : []);
    //
    //     return () => {
    //         isMounted = false;
    //     }
    // },[allUsers])

    useEffect(() => {
        let isMounted = true;

        if (isUserProfile) {
            isMounted && onSelectClick(isUserProfile)
        }

        return () => {
            isMounted = false;
        }
    }, [isUserProfile])

    function handleOptionsOpen() {
        if (isOptionsOpen) {
            setOptionsOpen(false);
        } else {
            setOptionsOpen(true);
        }
    }

    function onSelectClick(user) {
        setSelectUser({
            ...selectUser,
            nameUser: '',
            dataUser: user
        });
        setOptionsOpen(false);
        setDisableBtn(false);
        // setResultsForRender(allUsers && allUsers.length !== 0 ? allUsers : []);
        setResultsForRender([]);
        setUserProfile(user);
    }

    function onHandleInput(e) {
        setSelectUser({
            ...selectUser,
            nameUser: e.currentTarget.value
        });
        if (e.currentTarget.value === "") {
            onActiveButton(resultForRender, e.currentTarget.value)
            setOptionsOpen(false);
            // setResultsForRender(allUsers && allUsers.length !== 0 ? allUsers : []);
            setResultsForRender([]);
        } else {
            setMessage({
                isError: false,
                text: ""
            });
            setDisableBtn(false);
            const filterUser = allUsers.filter(el => {
                return el.fullName.toLocaleLowerCase().includes(e.currentTarget.value.toLocaleLowerCase());
            })
            onActiveButton(filterUser, e.currentTarget.value)
            setOptionsOpen(true);
            setResultsForRender(filterUser);
        }
    }

    function onActiveButton(results, user) {
        if (results.length === 0 || user === '') {
            setDisableBtn(true);
        } else {
            setDisableBtn(false);
        }
    }

    function onHandleSave() {
        if (newPass.value === '') {
            return setMessage({
                isError: true,
                text: 'Необходимо ввести пароль!'
            })
        }
        const body = {
            id: selectUser.dataUser.id,
            password: newPass.value
        }
        updateUserMutation.mutate({body: body})
    }

    useEffect(() => {
        let isMounted = true;
        if (newPass.value !== '') {
            isMounted && setMessage('');
        }
        return () => {
            isMounted = false;
        }
    }, [newPass.value])

    return (
        <div className="profile-user-update-data">
            <p className="edit-main-data-user__title-change-pass">Сброс пароля пользователя</p>
            <div className="edit-main-data-user__time-zone-main-container" style={{marginBottom: '0.88em'}}>
                <p className="edit-main-data-user__time-zone-heading">
                    Поиск пользователя
                    <span className="reg-main-block__red-star-heading_span">*</span>
                </p>
                <div
                    className="edit-main-data-user__time-zone-select-container"
                >
                    <input
                        type="text"
                        className="profile-user-update-data__select-value"
                        value={selectUser.nameUser}
                        onChange={(e) => onHandleInput(e)}
                        placeholder="Введите данные пользователя"
                    />
                    <img className="edit-main-data-user__time-zone-select-arrow profile-user-update-data__select-arrow"
                         src={searchIcon} alt="" style={{cursor: 'default'}}/>
                    {/*<img*/}
                    {/*    onClick={handleOptionsOpen}*/}
                    {/*    className="edit-main-data-user__time-zone-select-arrow profile-user-update-data__select-arrow"*/}
                    {/*    src={optionRow}*/}
                    {/*    alt="Стрелочка открытия меню"*/}
                    {/*/>*/}
                    {isOptionsOpen && (
                        <div className="edit-main-data-user__time-zone-options-container">
                            {resultForRender.length !== 0 ? (
                                <>
                                    {resultForRender?.map((user, index) => (
                                        <div
                                            className="edit-main-data-user__time-zone-option"
                                            style={{display: 'flex', flexDirection: 'column'}}
                                            key={index}
                                            onClick={() => onSelectClick(user)}
                                        >
                                            <p>
                                                {user.fullName?.includes('null') ? user.fullName : ''}
                                                {user.lastName} {user.firstName} {user.secondName}
                                            </p>
                                            <p style={{color: 'rgba(54, 59, 77, 0.4)'}}>
                                                {user.email}
                                            </p>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <p className="profile-user-update-data__not-found-option">
                                    Пользователь не найден!
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <span className="edit-main-data-user__time-zone-main-container__error-message"></span>
            </div>
            <div className="edit-main-data-user__surname">
                <label className="edit-main-data-user__surname-label">ФИО пользователя</label>
                <input className="edit-main-data-user__surname-field-input"
                       placeholder="ФИО пользователя"
                       value={Object.values(selectUser?.dataUser).length !== 0 ? `${(selectUser?.dataUser?.lastName || selectUser?.dataUser?.last_name) ?? ''} ${(selectUser?.dataUser?.firstName || selectUser?.dataUser?.first_name) ?? ''} ${(selectUser?.dataUser?.secondName || selectUser?.dataUser?.second_name) ?? ''}` : ''}
                       disabled={true}
                />
            </div>
            <div className="edit-main-data-user__surname">
                <label className="edit-main-data-user__surname-label">Email</label>
                <input className="edit-main-data-user__surname-field-input"
                       placeholder="Email пользователя"
                       value={Object.values(selectUser?.dataUser).length !== 0 ? selectUser?.dataUser?.email : ''}
                       disabled={true}
                />
            </div>
            <div className="edit-main-data-user__surname">
                <label className="edit-main-data-user__surname-label">Новый пароль</label>
                <input className="edit-main-data-user__surname-field-input"
                       placeholder="Введите пароль"
                       value={newPass.value}
                       onChange={newPass.onChange}
                />
            </div>
            <button
                disabled={isDisableBtn}
                onClick={onHandleSave}
                className={isDisableBtn ? "edit-main-data-user__button-save profile-user-update-data__button-not-active" : "edit-main-data-user__button-save"}
            >
                {constants.PROFILE_USER.PROFILE_USER_SAVE_BTN}
            </button>
            <p className={message.isError ? "profile-user-update-data__message-error" : "profile-user-update-data__message-success"}>{message.text}</p>
        </div>
    )
}
export default ProfileUserUpdateUserData;
import React, {useEffect, useState} from "react";
import PaginationBlock from "../PaginationBlock/PaginationBlock";
import iconDelete from "../../img/AddNewOrgDeleteIcon.svg";
import {Validation} from "../../utils/Validation/Validation";
import {useLocation} from "react-router-dom";
import {onSetCountPageForSearch} from "../../utils/OnSetCountPageForSearch/OnSetCountPageForSearch";

const UsersTable = (props) => {
    const {
        constants,
        onRemoveUserClick,
        handleChangeSuperUser,
        users,
        deleteUserButtonText,
        deleteUserButtonTextMobile,
        deleteUserId,
        adminId,
    } = props;

    const {pathname} = useLocation();
    const usersSearch = Validation();
    const [usersForRender, setUsersForRender] = useState([]);
    const [usersSearchInput, setUsersSearchInput] = useState("");
    const [showResultsFrom, setShowResultsFrom] = useState(0);
    const [resultsShow, setResultsShow] = useState(5);
    const [result, setResult] = useState(5);
    const [pageCount, setPageCount] = useState(1);
    const [selectedResultsShow, setSelectedResultsShow] = useState(5);
    const propertyCheckUsersArray = users?.some(el => el.last_name || el.first_name || el.second_name);

    function noAction() {
    }

    function handleShowResultsFrom(value) {
        setShowResultsFrom(value);
    }

    function handleResultsShow(value) {
        setResultsShow(value);
    }

    function showPrevResults() {
        if (resultsShow <= result) {
            return;
        } else {
            setShowResultsFrom(showResultsFrom - result);
            handleShowResultsFrom(showResultsFrom - result);
            setResultsShow(resultsShow - result);
            handleResultsShow(resultsShow - result);
            setPageCount(pageCount - 1);
        }
    }

    function showNextResults() {
        if (resultsShow >= usersForRender.length) {
            return;
        } else {
            setShowResultsFrom(0 + resultsShow);
            handleShowResultsFrom(0 + resultsShow);
            setResultsShow(result + resultsShow);
            handleResultsShow(result + resultsShow);
            setPageCount(pageCount + 1);
        }
    }

    function onChoiceClick(value) {
        setResultsShow(value);
        handleResultsShow(value);
        setResult(value);
        setSelectedResultsShow(value);
        setShowResultsFrom(0);
        handleShowResultsFrom(0);
        setPageCount(1);
    }

    function searchInput(value) {
        setUsersSearchInput(value);
    }

    useEffect(() => {
        let isMounted = true;
        if (users && users.length > 0) {
            if (usersSearchInput === "") {
                isMounted && setUsersForRender(users);
                isMounted && onChoiceClick(5);
            } else {
                const dataForRender = [];
                users.forEach((user) => {
                    if (
                        user?.email?.toLowerCase().includes(usersSearchInput.toLowerCase())
                    ) {
                        dataForRender.push(user);
                    } else if (
                        user?.lastName
                            ?.toLowerCase()
                            .includes(usersSearchInput.toLowerCase())
                        || user?.last_name
                            ?.toLowerCase()
                            .includes(usersSearchInput.toLowerCase())
                    ) {
                        dataForRender.push(user);
                    } else if (
                        user?.firstName
                            ?.toLowerCase()
                            .includes(usersSearchInput.toLowerCase())
                        || user?.first_name
                            ?.toLowerCase()
                            .includes(usersSearchInput.toLowerCase())
                    ) {
                        dataForRender.push(user);
                    } else if (
                        user?.secondName
                            ?.toLowerCase()
                            .includes(usersSearchInput.toLowerCase())
                        || user?.second_name
                            ?.toLowerCase()
                            .includes(usersSearchInput.toLowerCase())
                    ) {
                        dataForRender.push(user);
                    }
                });
                isMounted &&
                onSetCountPageForSearch(dataForRender?.length, onChoiceClick);
                isMounted && setUsersForRender(dataForRender);
            }
        } else {
            isMounted && setUsersForRender([]);
        }
        return () => {
            isMounted = false;
        };
    }, [usersSearchInput, users]);

    return (
        <div className="users-table">
            <div className="add-new-organization__top-pagination">
                <PaginationBlock
                    sortList={usersForRender}
                    search={usersSearch}
                    searchInput={searchInput}
                    onChoiceClick={onChoiceClick}
                    selectedResultsShow={selectedResultsShow}
                    pageCount={pageCount}
                    showPrevResults={showPrevResults}
                    showNextResults={showNextResults}
                    constants={constants}
                />
            </div>
            {usersForRender.length !== 0 ? (
                <div className="add-new-organization__table-list-users">
                    {usersForRender
                        .sort(function (a, b) {
                            var emailA = a?.email?.toLowerCase(),
                                emailB = b?.email?.toLowerCase();
                            if (emailA < emailB) return -1;
                            if (emailA > emailB) return 1;
                            return 0;
                        })
                        .slice(showResultsFrom, resultsShow)
                        .map((user, i) => (
                            <div key={i} className="table-list-users">
                                <div
                                    className={
                                        pathname === "/add-org-page"
                                            ? "table-list-users__name-user-icon-lock"
                                            : "table-list-users__name-user-icon-lock-add-group"
                                    }
                                >
                                    <p className="table-list-users__column-name">
                                        {propertyCheckUsersArray ? (
                                            <>
                                                {user.last_name} {user.first_name} {user.second_name}
                                            </>
                                        ) : (
                                            <>
                                                {user.lastName} {user.firstName} {user.secondName}
                                            </>
                                        )}
                                    </p>
                                </div>
                                <p
                                    className={
                                        pathname === "/add-org-page"
                                            ? "table-list-users__column-e-mail"
                                            : "table-list-users__column-e-mail-add-group"
                                    }
                                >
                                    {user.email}
                                </p>
                                {(pathname === "/org-settings" ||
                                    pathname === "/add-org-page") && (
                                    <div className="table-list-users__column-checkbox-superuser">
                                        <label className="table-list-users__checkbox_container">
                                            {user.id === adminId ? (
                                                <input
                                                    checked={user.isAdmin}
                                                    onChange={noAction}
                                                    type="checkbox"
                                                />
                                            ) : (
                                                <input
                                                    checked={user.isAdmin}
                                                    onChange={() => handleChangeSuperUser(user)}
                                                    type="checkbox"
                                                />
                                            )}
                                            <span className="table-list-users__checkmark"/>
                                        </label>
                                        <p className="column-checkbox-superuser__label">
                                            {constants.ADD_NEW_ORG.ADD_NEW_ORG_SUPERUSER}
                                        </p>
                                    </div>
                                )}
                                <div
                                    className="table-list-users__delete-icon-button"
                                    onClick={() => onRemoveUserClick(user)}
                                >
                                    <img
                                        alt={constants.GENERAL.ALT_ICON}
                                        src={iconDelete}
                                        className="delete-icon-button__icon-delete"
                                    />
                                    <p className="delete-icon-button__delete-btn">
                                        {deleteUserId === user.id
                                            ? deleteUserButtonText
                                            : constants.ADD_NEW_ORG.ADD_NEW_ORG_DELETE_BTN}
                                    </p>
                                    <p className="delete-icon-button__delete-btn-mobile">
                                        {deleteUserId === user.id
                                            ? deleteUserButtonTextMobile
                                            : constants.ADD_NEW_ORG.ADD_NEW_ORG_DELETE_BTN_MOBILE}
                                    </p>
                                </div>
                            </div>
                        ))}
                </div>
            ) : (
                <div className="table-list-users__no-users-container">
                    <p className="table-list-users__no-users">
                        {constants.ADD_NEW_ORG.ADD_NEW_ORG_NO_USERS}
                        <span className="name-org-input__red-star"> *</span>
                    </p>
                </div>
            )}
            <div className="add-new-organization__bottom-pagination">
                <PaginationBlock
                    sortList={usersForRender}
                    search={usersSearch}
                    searchInput={searchInput}
                    onChoiceClick={onChoiceClick}
                    selectedResultsShow={selectedResultsShow}
                    pageCount={pageCount}
                    showPrevResults={showPrevResults}
                    showNextResults={showNextResults}
                    constants={constants}
                />
            </div>
        </div>
    );
};

export default UsersTable;

import React, {useEffect, useState} from "react";
import {Route, Routes, useNavigate, useLocation} from "react-router-dom";
import {config} from "../../config";
import * as en from "../../utils/Localization/En/constants";
import * as ru from "../../utils/Localization/Ru/constants";
import Auth from "../Auth/Auth";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import OrganizationsList from "../OrganizationsList/OrganizationsList";
import AddNewOrganization from "../AddNewOrganization/AddNewOrganization";
import ProfileUser from "../ProfileUser/ProfileUser";
import timeZone from "../../utils/TimeZoneData/TimeZoneRu.json";
import * as UserAuth from "../../Api/Auth";
import * as Event from "../../Api/Events";
import * as Users from "../../Api/Users";
import * as Template from "../../Api/Template";
import GroupUsers from "../GroupUsers/GroupUsers";
import ListUsers from "../ListUsers/ListUsers";
import AddNewGroupUsers from "../AddNewGroupUsers/AddNewGroupUsers";
import Main from "../Main/Main";
import GroupUsersSelectNameGroup from "../GroupUsersSelectNameGroup/GroupUsersSelectNameGroup";
import VotesPage from "../VotesPage/VotesPage";
import AddNewVote from "../AddNewVote/AddNewVote";
import OrgSettings from "../OrgSettings/OrgSettings";
import ListTemplates from "../ListTemplates/ListTemplates";
import DetailsVote from "../DetailsVote/DetailsVote";
import DetailsVotePage from "../DetailsVote/DetailsVotePage";
import useLocalstorage from "../../utils/CustomHooks/useLocalstorage/useLocalstorage";
import PreLoaderBlock from "../PreLoaderBlock/PreLoaderBlock";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import {NoMatchRoutes} from "../../utils/NoMatchRoutes/NoMatchRoutes";
import {appRequestsAuthUser} from "../../utils/AppAllRequestsForRoleUser/appAllRequestsForRoleUser";
import {appGetCommonStatistic} from "../../utils/AppGetCommonStatistic/appGetCommonStatistic";
import ListModerators from "../ListModerators/ListModerators";
import {Stomp} from "@stomp/stompjs";
import {useLocalStorageVersion} from "../../hooks/useLocalStorageVersion";
import QrLogins from "../QrLogins/QrLogins";
import useRefreshToken from "../../hooks/useRefreshToken";

function App() {

    const navigate = useNavigate();
    const {
        currentUser,
        setCurrentUser,
        isUserProfile,
        setUserProfile,
        stateReducer,
        runUpdateDataEvents,
        resetStateAddEvents,
    } = useCurrentUserContext();

    const [isReloadPage, setReloadPage] = useState(false);
    const [constants, setConstants] = useState(ru.constants);
    const [changeLanguageBtn, setChangeLanguageBtn] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isRememberMe, setRememberMe] = useState(true);
    // const [allEvents, setAllEvents] = useLocalstorage([], "eventList");
    const [allEvents, setAllEvents] = useState([]);
    const [allGroups, setAllGroups] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [allModerators, setAllModerators] = useState([]);
    const [allTemplates, setAllTemplates] = useState([]);
    const [isCommonStatistic, setCommonStatistic] = useState([]);
    const [userName, setUserName] = useState("");
    const [isAuthFormValid, setAuthFormValid] = useState(true);
    const [authError, setAuthError] = useState("");
    const [isUserProfileErrorMessage, setUserProfileErrorMessage] = useState("");
    const [isPreloaderAuthBtn, setPreloaderAuthBtn] = useState(false);
    const [authAs, setAuthAs] = useState("");
    const [isSelectedTemplate, setSelectedTemplate] = useState({});
    const [utcOffset, setUtcOffset] = useLocalstorage("", "utcOffset");
    const [changeUtcOffset, setChangeUtcOffset] = useLocalstorage(
        "",
        "changeUtcOffset"
    );
    const {pathname} = useLocation();
    const [isPreloaderActive, setPreloaderActive] = useState(false);

    const [isStatisticsUpdate, setIsStatisticsUpdate] = useState(false);
    const refresh = useRefreshToken();

    const controller = new AbortController();

    useLocalStorageVersion();

    function requestHelper(request, body = {}) {
        return new Promise((resolve, reject) => {
            if (localStorage.getItem("accessToken")) {
                const accessToken = localStorage.getItem("accessToken");
                const refreshToken = localStorage.getItem("refreshToken");
                request(accessToken, body)
                    .then((res) => {
                        if (res.status && res.status === "failure") {
                            UserAuth.login({type: "refresh-token", token: refreshToken, authAs: authAs,})
                                .then((newTokens) => {
                                    if (!newTokens.accessToken) {
                                        logout();
                                        // return;
                                        // setTimeout(() => {
                                        //   window.location.reload();
                                        // }, 10000);
                                        // return;
                                        // logout();
                                    } else {
                                        // // const { accessToken, refreshToken } = JSON.stringify(newTokens);
                                        // console.log("new tokens: ", newTokens.json());
                                        // localStorage.setItem("accessToken", accessToken);
                                        // localStorage.setItem("refreshToken", refreshToken);
                                        request(accessToken, body)
                                            .then((res) => {
                                                resolve(res);
                                            })
                                            .catch((err) => {
                                                throw new Error(err.message);
                                            });
                                    }
                                })
                                .catch((err) => {
                                    if (err.message) {
                                        controller.abort()
                                        throw new Error(err.message);
                                    }
                                });
                        } else {
                            resolve(res);
                        }
                    })
                    .catch((err) => {
                        refresh().then(res => {
                            if (res.status === 401) {
                                logout();
                            } else {
                                // runUpdateDataEvents();
                            }
                        });
                        disactivatePreloader();
                        if (err.message) {
                            controller.abort();
                            logout();
                            console.log(err.message)
                            throw new Error(err.message);
                        }
                    });
            } else {
                logout();
            }
        });
    }

    function initStompClient(ws_connect) {
        let client = Stomp.client(ws_connect);
        client.debug = function () {
        };
        // client.connectionTimeout = 10000;
        return client;
    }

    // function _handleMessage(message) {

    //   if (message.body.includes("Update for")) {
    //     onUpdateWebSocketEvent(message.body);
    //   }
    // }

    useEffect(() => {
        if (isReloadPage) {
            requestHelper(Event.getEvents)
                .then((data) => {
                    if (data.status === "failure") {
                        setAllEvents([]);
                    } else {
                        setAllEvents(data);
                        handleReloadPage();
                        data.forEach((event) => {
                            if (event.status !== "ended") {
                                if (event.status !== "quorum_unpresant") {
                                    subscribeToEvent(event.id, config);
                                }
                            }
                        });
                    }
                })
                .catch((err) => {
                    throw new Error(err.message);
                });
        }
        // eslint-disable-next-line
    }, [isReloadPage]);

    function handleMessage() {
        setReloadPage(true);
    }

    function handleMessageDelete(message) {
        window.location.reload();
    }

    function handleStatisticsMessage() {
        var cur_time = Date.now();
        if (!window.getNetworkStatistics_last_started_time) {
            window.getNetworkStatistics_last_started_time = 0;
            window.getNetworkStatistics_started = 0;
        }

        var delay = 1000;
        var delay_retry = 5000;
        //window.delay_timer;

        var cond1 =
            window.getNetworkStatistics_last_started_time + delay_retry < cur_time;

        if (
            cond1 ||
            (!window.getNetworkStatistics_started &&
                window.getNetworkStatistics_last_started_time + delay < cur_time)
        ) {
            window.getNetworkStatistics_last_started_time = cur_time;
            window.getNetworkStatistics_started = 1;
            setIsStatisticsUpdate(true);
        } else if (!cond1) {
            if (window.delay_timer) {
                clearTimeout(window.delay_timer);
                window.delay_timer = 0;
            }
            window.delay_timer = setTimeout(
                () => setIsStatisticsUpdate(true),
                delay_retry
            );
        }
    }

    function subscribeToEvent(eventId, config) {
        const client = initStompClient(config.ws_connect);
        const on_connect = function (x) {
            client.subscribe(`/exchange/${eventId}/time`, handleMessage, {});
            client.subscribe(`/exchange/${eventId}/delete`, handleMessageDelete, {});
            client.subscribe(
                `/exchange/${eventId}/event_statistics`,
                handleStatisticsMessage,
                {}
            );
        };

        const on_error = function (err, tmp) {
        };

        client.connect(config.ws_user, config.ws_pass, on_connect, on_error, "/");
        client.onWebSocketClose = function (evt) {
        };
    }

    function subscribeToNewEvents(userId, config) {
        const client = initStompClient(config.ws_connect);
        const on_connect = function (x) {
            client.subscribe(`/exchange/events/${userId}`, handleMessage, {});
        };

        const on_error = function (err, tmp) {
        };

        client.connect(config.ws_user, config.ws_pass, on_connect, on_error, "/");
        client.onWebSocketClose = function (evt) {
        };
    }

    function firstSubscribeToEvents(events) {
        if (events.length !== 0) {
            events.forEach((event) => {
                if (event.status !== "ended") {
                    if (event.status !== "quorum_unpresant") {
                        subscribeToEvent(event.id, config);
                    }
                }
            });
        }
    }

    function handleReloadPage() {
        setReloadPage(false);
    }

    // function onUpdateWebSocketEvent(currentEventId) {
    //   console.log(
    //     "i am in on Update WebSocket func, this is currentEventId: ",
    //     currentEventId
    //   );

    //   let eventId = currentEventId.slice(11);
    //   const body = {
    //     id: eventId,
    //   };
    //   requestHelper(Event.getEvent, body)
    //     .then((data) => {
    //       if (data.status === "failure") {
    //         return allEvents;
    //       } else {
    //         console.log(data);
    //         const filteredEvents = allEvents.filter((el) => el.id !== eventId);
    //         setAllEvents([...filteredEvents, data]);
    //         allEvents.forEach((event) => {
    //           if (event.status !== "ended") {
    //             if (event.status !== "quorum_unpresant") {
    //               subscribeToEvent(event.id, config);
    //             }
    //           }
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       throw new Error(err.message);
    //     });
    // }

    useEffect(() => {
        let isMounted = true;
        if (pathname === "/auth" && isLoggedIn === true) {
            isMounted && navigate("/");
            window.scrollTo(0, 0);
        } else if (pathname === "/auth" && isLoggedIn === false) {
            isMounted && navigate("/auth");
        }
        return () => {
            isMounted = false;
        };
    }, [pathname, isLoggedIn]);

    function handleLangChange(value) {
        const lang = {
            lang: value,
        };
        if (value === "ru") {
            setConstants(ru.constants);
            localStorage.setItem("lang", JSON.stringify(lang));
            setChangeLanguageBtn(true);
        } else {
            setConstants(en.constants);
            localStorage.setItem("lang", JSON.stringify(lang));
            setChangeLanguageBtn(false);
        }
    }

    function setOffset(utc) {
        if (utc !== undefined) {
            const localOffset = timeZone.find(
                (value) => value.VALUE === utc.toString()
            );
            setUtcOffset(localOffset.LABEL);
            setChangeUtcOffset(localOffset.VALUE);
        } else {
            console.log("");
        }
    }

    useEffect(() => {
        let isMounted = true;
        if (localStorage.getItem("lang")) {
            const storageLang = localStorage.getItem("lang");
            const lang = JSON.parse(storageLang);
            if (lang.lang === "en") {
                isMounted && setConstants(en.constants);
                isMounted && setChangeLanguageBtn(false);
            } else {
                isMounted && setConstants(ru.constants);
                isMounted && setChangeLanguageBtn(true);
            }
        } else {
            isMounted && setConstants(ru.constants);
        }
        return () => {
            isMounted = false;
        };
    }, []);

    function handleRememberMe() {
        if (isRememberMe) {
            setRememberMe(false);
        } else {
            setRememberMe(true);
        }
    }

    function activatePreloader() {
        setPreloaderActive(true);
    }

    function disactivatePreloader() {
        setPreloaderActive(false);
    }

    function addCurrentUser(user) {
        setCurrentUser(user);
    }

    const userDefaultName = {
        lastName: "Неизвестный",
        firstName: "Пользователь",
    };

    function createUserName(user) {
        const firstName = function () {
            if (user.firstName) {
                return `${user.firstName.charAt(0)}`;
            }
            return `${userDefaultName.firstName.charAt(0)}`;
        };

        const lastName = function () {
            if (user.lastName) {
                return user.lastName;
            }
            return userDefaultName.lastName;
        };
        const middleName = function () {
            if (user.secondName) {
                return `${user.secondName.charAt(0)}.`;
            }

            return "";
        };
        const shortName = `${lastName()} ${firstName()}.${middleName()}`;
        setUserName(shortName);
    }

    function handleAuthError(isValid) {
        setAuthFormValid(isValid);
    }

    function handleAuthErrorMessage(message) {
        setAuthError(message);
    }

    function logout() {
        if (localStorage.getItem("user")) {
            localStorage.removeItem("user");
        }
        if (localStorage.getItem("jwt")) {
            localStorage.removeItem("jwt");
        }
        if (localStorage.getItem("accessToken")) {
            localStorage.removeItem("accessToken");
        }
        if (localStorage.getItem("refreshToken")) {
            localStorage.removeItem("refreshToken");
        }
        if (localStorage.getItem("authAs")) {
            localStorage.removeItem("authAs");
        }
        if (localStorage.getItem("eventList")) {
            localStorage.removeItem("eventList");
        }
        if (localStorage.getItem("currentEvent")) {
            localStorage.removeItem("currentEvent");
        }
        if (localStorage.getItem("currentOrgId")) {
            localStorage.removeItem("currentOrgId");
        }
        if (localStorage.getItem("currentGroupId")) {
            localStorage.removeItem("currentGroupId");
        }
        if (localStorage.getItem("utcOffset")) {
            localStorage.removeItem("utcOffset");
        }
        if (localStorage.getItem("changeUtcOffset")) {
            localStorage.removeItem("changeUtcOffset");
        }
        if (localStorage.getItem("selectedUser")) {
            localStorage.removeItem("selectedUser");
        }
        setLoggedIn(false);
        setCurrentUser({});
        setUserName("");
        navigate("/auth");
        setAuthAs('');
        disactivatePreloader();
    }

    function handleCurrentEvents(data) {
        const currentEvent = {
            id: data.id,
        };
        if (localStorage.getItem("currentEvent")) {
            localStorage.removeItem("currentEvent");
            localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
            navigate(`/details-vote/${data.id}`);
        } else {
            localStorage.setItem("currentEvent", JSON.stringify(currentEvent));
            navigate(`/details-vote/${data.id}`);
        }
    }

    function handleSelectUser(userId) {
        if (userId === currentUser.id) {
            navigate("/profile-user");
            setUserProfile(currentUser);
            window.scrollTo(0, 0);
        } else {
            requestHelper(Users.getUser, userId)
                .then((data) => {
                    setUserProfile(data);
                    navigate("/profile-user");
                })
                .catch((err) => {
                    throw new Error(err.message);
                });
            window.scrollTo(0, 0);
        }
    }

    function handleResetIsSelectedTemplateMode(nameButton, setActive) {
        switch (nameButton) {
            case "addVote":
                setSelectedTemplate({});
                navigate("/add-new-vote");
                resetStateAddEvents("addVote");
                break;
            case "addTemplate":
                setSelectedTemplate({});
                navigate("/add-new-template");
                resetStateAddEvents("addTemplate");
                break;
            case "addUser":
                setSelectedTemplate({});
                navigate("/add-user");
                resetStateAddEvents("addUser");
                break;
            case "linkAddNewVote":
                setSelectedTemplate({});
                navigate("/add-new-vote");
                break;
            default:
                return;
        }
        if (setActive !== undefined) {
            setActive(false);
        }
    }

    function handleSelectTemplate(template) {
        requestHelper(Template.getTemplate, template)
            .then((data) => {
                setSelectedTemplate(data);
                navigate("/add-new-vote");
            })
            .catch((err) => {
                throw new Error(err.message);
            });
    }

    function handleChangeDataUser(profileUser, bodyChangeDataUser) {
        const nameRegExp = /^([а-яё\s-]+|[a-z\s-]+)$/i;
        const passRegExp =
            /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*,.:;+<>{}?\\[\]/_-]{8,64}$/;
        const {repeatPassword, ...modifyBodyRequest} = bodyChangeDataUser;
        if (
            nameRegExp.test(bodyChangeDataUser.lastName) === false ||
            nameRegExp.test(bodyChangeDataUser.firstName) === false ||
            nameRegExp.test(bodyChangeDataUser.secondName) === false
        ) {
            setUserProfileErrorMessage(
                `${constants.PROFILE_USER.PROFILE_USER_CHANGE_DATA_ERROR_WRONG_FORMAT}`
            );
            return;
        } else if (
            bodyChangeDataUser.password !== bodyChangeDataUser.repeatPassword
        ) {
            setUserProfileErrorMessage(
                `${constants.PROFILE_USER.PROFILE_USER_CHANGE_DATA_ERROR_PASSWORD_MISMATCH}`
            );
            return;
        } else if (
            bodyChangeDataUser.password !== "" ||
            bodyChangeDataUser.repeatPassword !== ""
        ) {
            if (
                passRegExp.test(bodyChangeDataUser.password) === false ||
                passRegExp.test(bodyChangeDataUser.repeatPassword) === false
            ) {
                setUserProfileErrorMessage(
                    `${constants.PROFILE_USER.PROFILE_USER_CHANGE_DATA_ERROR_RULE_PASS}`
                );
                return;
            }
        }
        const body = {
            id: profileUser?.id,

            password: modifyBodyRequest.password,

            firstName: modifyBodyRequest.first_name,
            secondName: modifyBodyRequest.second_name,
            lastName: modifyBodyRequest.last_name,
        };
        if (profileUser?.id === currentUser?.id) {
            requestHelper(Users.patchUser, body)
                .then((data) => {
                    if (data.status === "failure") {
                        console.log(data);
                    } else {
                        let userObjStorage = localStorage.getItem("user");
                        localStorage.removeItem("key");
                        let userObjStorageParse = JSON.parse(userObjStorage);
                        let updateUserObjStorageParse = {
                            ...userObjStorageParse,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            secondName: data.secondName,
                        };
                        localStorage.setItem(
                            "user",
                            JSON.stringify(updateUserObjStorageParse)
                        );
                        createUserName(updateUserObjStorageParse);
                        setUserProfileErrorMessage("");
                        handleSelectUser(profileUser.id);
                        setOffset(data.utcOffset);
                    }
                })
                .catch((err) => {
                    throw new Error(err.message);
                });
        } else {
            requestHelper(Users.patchUser, body)
                .then((data) => {
                    if (data.status === "failure") {
                        console.log(data);
                    } else {
                        setUserProfileErrorMessage("");
                        handleSelectUser(profileUser.id);
                    }
                })
                .catch((err) => {
                    throw new Error(err.message);
                });
        }
    }

    function handleDeleteEvents(data) {
        const body = [data.id];
        activatePreloader();
        requestHelper(Event.deleteEvent, body)
            .then((data) => {
                if (data) {
                    runUpdateDataEvents();
                    navigate("/list-votes");
                } else {
                    navigate("/");
                }
            })
            .catch((err) => {
                throw new Error(err.message);
            })
            .finally(() => {
                disactivatePreloader();
            });
    }

    // // не актуально, оставил забрать обработки ошибок
    // function handleLogin(email, password, authAs) {
    //   setPreloaderAuthBtn(true);
    //   UserAuth.login({
    //     username: email,
    //     password: password,
    //     authAs: authAs,
    //   })
    //     .then((res) => {
    //       if (!res.ok) {
    //         const data = res.json();
    //         handleAuthError(false);
    //         data.message &&
    //           handleAuthErrorMessage(constants.AUTH.AUTH_ERROR_MESSAGE);
    //       } else {
    //         if (isRememberMe) {
    //           localStorage.setItem("user", JSON.stringify(res));
    //         }

    //         handleAuthError(true);
    //         handleAuthErrorMessage("");
    //         setLoggedIn(true);
    //         addCurrentUser(res);
    //         createUserName(res);
    //         setOffset(res.utcOffset);

    //         const subUserId = `${res.authAs + res.id}`;
    //         subscribeToNewEvents(subUserId, config);
    //         navigate("/");
    //       }
    //     })
    //     .catch((err) => {
    //       throw new Error(err.message);
    //     })
    //     .finally(() => {
    //       setPreloaderAuthBtn(false);
    //     });
    // }
    // //

    useEffect(() => {
        let isMounted = true;

        if (isLoggedIn) {
            appRequestsAuthUser(
                currentUser,
                requestHelper,
                isMounted,
                setAllEvents,
                setAllGroups,
                setAllUsers,
                setAllTemplates,
                setAllModerators,
                firstSubscribeToEvents,
                authAs
            );
            appGetCommonStatistic(requestHelper, isMounted, setCommonStatistic);
        }

        return () => {
            isMounted = false;
        };
    }, [isLoggedIn, authAs, stateReducer.updateDataEvents]);

    useEffect(() => {
        let isMounted = true;
        if (localStorage.getItem("user")) {
            const userData = localStorage.getItem("user");
            const user = JSON.parse(userData);
            const userAuthAs = user?.authorities[0]?.role;
            isMounted && addCurrentUser(user);
            isMounted && createUserName(user);
            isMounted && setLoggedIn(true);
            isMounted && setAuthAs(userAuthAs);
            const subUserId = `${userAuthAs}${user.id}`;
            subscribeToNewEvents(subUserId, config);

            setOffset(user.utcOffset);

            if (
                !(
                    pathname === "/" ||
                    pathname === "/add-org-page" ||
                    pathname === "/organizations" ||
                    pathname === "/profile-user" ||
                    pathname === "/org-settings" ||
                    pathname === "/add-new-group" ||
                    pathname === "/add-new-vote" ||
                    pathname === "/group-users" ||
                    pathname === "/group-users/selected-name-group" ||
                    pathname === "/list-users" ||
                    pathname === "/list-votes" ||
                    pathname === "/list-templates" ||
                    pathname === "/add-new-template" ||
                    pathname === "/list-moderators" ||
                    pathname === "/statistics" ||
                    pathname.includes("/details-vote")
                )
            ) {
                navigate("/");
            }
        } else {
            if (!(pathname === "/auth")) {
                logout();
            }
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line
    }, []);

    function onOrgSettingsClick(org) {
        const currentOrg = {
            id: org.id,
        };
        localStorage.setItem("currentOrgId", JSON.stringify(currentOrg));
        navigate("/org-settings");
    }

    function onHandleGroupClick(groupId) {
        localStorage.setItem("currentGroupId", JSON.stringify(groupId));
        navigate("/group-users/selected-name-group");
    }

    function formatDate(serverDate) {
        const localDate = new Date(serverDate.toString());
        const defaultDate = localDate.getDate();
        const date = `${
            defaultDate.toString().length === 1
                ? `${"0" + defaultDate}`
                : `${defaultDate}`
        }`;
        const defaultMonth = localDate.getMonth() + 1;
        const month = `${
            defaultMonth.toString().length === 1
                ? `${"0" + defaultMonth}`
                : `${defaultMonth}`
        }`;
        const year = localDate.getFullYear();
        return `${date + "." + month + "." + year}`;
    }

    function formatTime(serverDate) {
        const localDate = new Date(serverDate);
        const currentDate = new Date();
        const getUtsCurrent = currentDate.getTimezoneOffset();
        const localDateUtc = localDate.getTimezoneOffset();
        if (localDateUtc !== Number(changeUtcOffset * -60)) {
            const serverOffsetMillis = 60 * 1000 * Number(changeUtcOffset * -60);
            const sumGetUtccurrent = 60 * 1000 * getUtsCurrent;
            const localOffset = new Date(
                localDate.getTime() - serverOffsetMillis + sumGetUtccurrent
            );
            const defaultHours = localOffset.getHours();
            const hoursChangeUtc = `${
                defaultHours.toString().length === 1
                    ? `${"0" + defaultHours}`
                    : `${defaultHours}`
            }`;
            const defaultMinutes = localDate.getMinutes();
            const minutes = `${
                defaultMinutes.toString().length === 1
                    ? `${"0" + defaultMinutes}`
                    : `${defaultMinutes}`
            }`;
            return `${hoursChangeUtc + ":" + minutes}`;
        } else {
            const defaultHours = localDate.getHours();
            const hours = `${
                defaultHours.toString().length === 1
                    ? `${"0" + defaultHours}`
                    : `${defaultHours}`
            }`;
            const defaultMinutes = localDate.getMinutes();
            const minutes = `${
                defaultMinutes.toString().length === 1
                    ? `${"0" + defaultMinutes}`
                    : `${defaultMinutes}`
            }`;
            return `${hours + ":" + minutes}`;
        }
    }

    return (
        <div className="app">
            {isPreloaderActive && <PreLoaderBlock/>}
            {isLoggedIn &&
                pathname !== "/statistics" &&
                !pathname.includes("/qr-logins") && (
                    <Header
                        constants={constants}
                        handleLogout={logout}
                        handleSelectUser={handleSelectUser}
                        handleResetIsSelectedTemplateMode={
                            handleResetIsSelectedTemplateMode
                        }
                        userName={userName}
                        authAs={authAs}
                    />
                )}
            <Routes>
                <Route
                    path={"/auth"}
                    element={
                        <Auth
                            subscribeToNewEvents={subscribeToNewEvents}
                            setOffset={setOffset}
                            createUserName={createUserName}
                            addCurrentUser={addCurrentUser}
                            setLoggedIn={setLoggedIn}
                            handleLangChange={handleLangChange}
                            constants={constants}
                            changeLanguageBtn={changeLanguageBtn}
                            handleRememberMe={handleRememberMe}
                            isRememberMe={isRememberMe}
                            config={config}
                            isAuthFormValid={isAuthFormValid}
                            handleAuthError={handleAuthError}
                            handleAuthErrorMessage={handleAuthErrorMessage}
                            authError={authError}
                            setAuthError={setAuthError}
                            isPreloaderAuthBtn={isPreloaderAuthBtn}
                            setUserAuthAs={setAuthAs}
                        />
                    }
                />
                <Route
                    exact
                    path={"/"}
                    element={
                        <Main isCommonStatistic={isCommonStatistic} constants={constants}/>
                    }
                />
                <Route
                    exact
                    path={"/organizations"}
                    element={
                        <OrganizationsList
                            constants={constants}
                            requestHelper={requestHelper}
                            onOrgSettingsClick={onOrgSettingsClick}
                        />
                    }
                />
                <Route
                    path={"/org-settings"}
                    element={
                        <OrgSettings
                            constants={constants}
                            requestHelper={requestHelper}
                            authAs={authAs}
                        />
                    }
                />
                <Route
                    path={authAs === "ROLE_SUPER_ADMIN" ? "/add-org-page" : "/"}
                    element={
                        <AddNewOrganization
                            constants={constants}
                            requestHelper={requestHelper}
                        />
                    }
                />
                <Route
                    path={authAs === "ROLE_ADMIN" ? "/add-new-group" : "/"}
                    element={
                        <AddNewGroupUsers
                            constants={constants}
                            requestHelper={requestHelper}
                        />
                    }
                />
                <Route
                    path={authAs === "ROLE_ADMIN" ? "/add-new-vote" : "/"}
                    element={
                        <AddNewVote
                            allUsers={allUsers}
                            allGroups={allGroups}
                            isSelectedTemplate={isSelectedTemplate}
                            constants={constants}
                            requestHelper={requestHelper}
                        />
                    }
                />
                <Route
                    path={authAs === "ROLE_ADMIN" ? "/add-new-template" : "/"}
                    element={
                        <AddNewVote
                            isSelectedTemplate={isSelectedTemplate}
                            constants={constants}
                            requestHelper={requestHelper}
                        />
                    }
                />
                <Route
                    path={"/profile-user"}
                    element={
                        <ProfileUser
                            handleCurrentEvents={handleCurrentEvents}
                            onOrgSettingsClick={onOrgSettingsClick}
                            onHandleGroupClick={onHandleGroupClick}
                            handleChangeDataUser={handleChangeDataUser}
                            isUserProfileErrorMessage={isUserProfileErrorMessage}
                            isUserProfile={isUserProfile}
                            setUserProfileErrorMessage={setUserProfileErrorMessage}
                            constants={constants}
                            authAs={authAs}
                            allUsers={allUsers}
                            requestHelper={requestHelper}
                        />
                    }
                />
                <Route
                    exact
                    path={"/group-users"}
                    element={
                        <GroupUsers
                            constants={constants}
                            authAs={authAs}
                            allGroups={allGroups}
                            requestHelper={requestHelper}
                            onHandleGroupClick={onHandleGroupClick}
                        />
                    }
                />
                <Route
                    path={"/group-users/selected-name-group"}
                    element={
                        <GroupUsersSelectNameGroup
                            constants={constants}
                            authAs={authAs}
                            requestHelper={requestHelper}
                        />
                    }
                />
                <Route
                    path={"/list-users"}
                    element={
                        <ListUsers
                            allUsers={allUsers}
                            handleSelectUser={handleSelectUser}
                            constants={constants}
                        />
                    }
                />
                <Route
                    path={authAs === "ROLE_SUPER_ADMIN" ? "/list-moderators" : "/"}
                    element={
                        <ListModerators
                            requestHelper={requestHelper}
                            allModerators={allModerators}
                            constants={constants}
                        />
                    }
                />
                <Route
                    exact
                    path={"/list-votes"}
                    element={
                        <VotesPage
                            constants={constants}
                            authAs={authAs}
                            allEvents={allEvents}
                            handleCurrentEvents={handleCurrentEvents}
                            handleDeleteEvents={handleDeleteEvents}
                            formatDate={formatDate}
                            formatTime={formatTime}
                            utcOffset={utcOffset}
                        />
                    }
                />
                <Route
                    path={`/details-vote/:id`}
                    element={
                        <DetailsVote
                            constants={constants}
                            authAs={authAs}
                            requestHelper={requestHelper}
                            activatePreloader={activatePreloader}
                            handleResetIsSelectedTemplateMode={
                                handleResetIsSelectedTemplateMode
                            }
                            disactivatePreloader={disactivatePreloader}
                            formatDate={formatDate}
                            formatTime={formatTime}
                            isReloadPage={isReloadPage}
                            isStatisticsUpdate={isStatisticsUpdate}
                            setIsStatisticsUpdate={setIsStatisticsUpdate}
                        />
                    }
                />
                <Route
                    path={"/statistics"}
                    element={
                        <DetailsVotePage
                            constants={constants}
                            authAs={authAs}
                            requestHelper={requestHelper}
                            activatePreloader={activatePreloader}
                            handleResetIsSelectedTemplateMode={
                                handleResetIsSelectedTemplateMode
                            }
                            disactivatePreloader={disactivatePreloader}
                            formatDate={formatDate}
                            formatTime={formatTime}
                            isReloadPage={isReloadPage}
                            isStatisticsUpdate={isStatisticsUpdate}
                            setIsStatisticsUpdate={setIsStatisticsUpdate}
                        />
                    }
                />
                <Route
                    exact
                    path={"/list-templates"}
                    element={
                        <ListTemplates
                            constants={constants}
                            allTemplates={allTemplates}
                            handleSelectTemplate={handleSelectTemplate}
                            requestHelper={requestHelper}
                            formatDate={formatDate}
                            formatTime={formatTime}
                        />
                    }
                />
                <Route
                    path={"/qr-logins/:id"}
                    element={<QrLogins requestHelper={requestHelper}/>}
                />
                <Route
                    path={"*"}
                    element={
                        <NoMatchRoutes constants={constants} isLoggedIn={isLoggedIn}/>
                    }
                />
            </Routes>
            {isLoggedIn &&
                pathname !== "/statistics" &&
                !pathname.includes("/qr-logins") && (
                    <Footer
                        handleSelectUser={handleSelectUser}
                        handleLangChange={handleLangChange}
                        constants={constants}
                        changeLanguageBtn={changeLanguageBtn}
                        authAs={authAs}
                    />
                )}
        </div>
    );
}

export default App;
